import React from 'react'
import { Helmet } from 'react-helmet'
import Layout from '../components/layout'
import SEO from '../components/seo'
import { Breadcrumb } from '../components/Breadcrumbs/Breadcrumbs'
import { PRIVACY_POLICY as PRIVACY_POLICY_LINK } from '../components/internal-links'
import {
  generateBreadcrumbsSchema,
  generateOrgSchema,
  generateWebsiteSchema,
} from '../lib/generate-schema'
import {
  pokerOneLinksStaticPage,
  rummyOneLinksStaticPage,
} from '../components/one-links'
import { HOMEPAGE_BREADCRUMB_TITLE } from '../utils/constnst.utils'
import PrivacyPolicyDocument from '../components/PrivacyPolicy/PrivacyPolicy'

const breadcrumbs: Breadcrumb[] = [
  {
    title: HOMEPAGE_BREADCRUMB_TITLE,
    url: '/',
  },
  {
    title: 'Privacy Policy',
    url: PRIVACY_POLICY_LINK,
  },
]

const PrivacyPolicy: React.FC = () => (
  <Layout
    showBreadcrumbs
    breadcrumbs={breadcrumbs}
    pokerOneLink={pokerOneLinksStaticPage}
    rummyOneLink={rummyOneLinksStaticPage}
  >
    <Helmet>
      {process.env.GATSBY_STAGING === 'true' && (
        <meta name="robots" content="noindex" />
      )}
    </Helmet>
    <SEO
      title="Privacy Policy: Read About the Privacy Policy at Mega"
      description="Read about the privacy policy at Mega. Get to know about data collection, usage, and protection by Mega. Click to know more!"
      organizationSchema={generateOrgSchema()}
      websiteSchema={generateWebsiteSchema()}
      breadcrumbsSchema={generateBreadcrumbsSchema(breadcrumbs)}
    />
    <PrivacyPolicyDocument />
  </Layout>
)

export default PrivacyPolicy
